import { render, staticRenderFns } from "./Gastro.vue?vue&type=template&id=769e5fdb&scoped=true"
import script from "./Gastro.vue?vue&type=script&lang=js"
export * from "./Gastro.vue?vue&type=script&lang=js"
import style0 from "./Gastro.vue?vue&type=style&index=0&id=769e5fdb&prod&scoped=true&lang=css"
import style1 from "./Gastro.vue?vue&type=style&index=1&id=769e5fdb&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "769e5fdb",
  null
  
)

export default component.exports